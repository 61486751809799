<template>

	<layout :include_header="false">

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex flex-column">

					<div class="w-100 h-100 position-relative">

						<div class="w-100 h-100 overflow-hidden">

							<div v-if="wp_loading" class="position-absolute top-50 start-50 translate-middle">
								<span class="spinner-border" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
							</div>

							<div class="w-100 h-100 position-absolute top-0 start-0 p-4">
								<div class="w-100 h-100 border rounded mx-auto overflow-hidden" style="max-width: 1600px">
									
									<div class="border-bottom bg-white p-2 d-flex justify-content-between align-items-center">

										<div></div>

										<div class="btn-group btn-group-sm">
											<button v-on:click="preview_size = 'mobile'" class="btn d-flex align-items-center" :class="preview_size == 'mobile' ? 'btn-primary' : 'btn-outline-primary'">
												<span class="material-icons-outlined">smartphone</span>
											</button>
											<button v-on:click="preview_size = 'tablet'" class="btn d-flex align-items-center" :class="preview_size == 'tablet' ? 'btn-primary' : 'btn-outline-primary'">
												<span class="material-icons-outlined">tablet_mac</span>
											</button>
											<button v-on:click="preview_size = 'desktop'" class="btn d-flex align-items-center" :class="preview_size == 'desktop' ? 'btn-primary' : 'btn-outline-primary'">
												<span class="material-icons-outlined">desktop_windows</span>
											</button>
										</div>
									</div>

									<iframe
									v-on:load="load_iframe" 
									name="iframe-preview"
									:src="iframe_preview"
									class="h-100 d-block mx-auto"
									:class="[(wp_loading || wp_transition || loading ? 'opacity-0' : '')]"
									:style="'width:' + iframe_width"
									></iframe>
								</div>
							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'

export default {
	name: 'site.prototype-preview',

	components: {
		layout
	},

	data() {
		return {
			wp_loading: false,
			wp_transition: false,
			wp_submitted: false,
			loading: false,
			success: null,
			site: null,
			workspace: null,
			error: {},
			display_page: 'prototype',
			preview_size: 'desktop',
			version: 0,
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		iframe_width()
		{
			var width = '100%';

			if ( this.preview_size == 'mobile' ) {
				width = '340px'
			}

			if ( this.preview_size == 'tablet' ) {
				width = '820px'
			}

			return width
		},

		iframe_preview()
		{
			var url = this.decrypt( this.$route.params.prototype_url ) + '?wireframe=true'

			return url
		},

	},

	mounted()
	{
		
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		load_iframe()
		{
			setTimeout( () => {
				this.wp_loading = false
				this.wp_transition = false
			}, 1000)
		},

		decrypt( encoded )
		{
			const salt = 'ueol~y=P36j2'
			const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
			const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
			return encoded
				.match(/.{1,2}/g)
				.map((hex) => parseInt(hex, 16))
				.map(applySaltToChar)
				.map((charCode) => String.fromCharCode(charCode))
				.join("");
		}
	}
}
</script>

<style>
iframe {
	transition: width 0.5s ease-in-out;
}
</style>
